import React from 'react';
import { NamedLink } from '../../../../components';
import { useConfiguration } from '../../../../context/configurationContext';
import css from './CategoryModal.module.css';

const data = [
  {
    id: 1,
    label: 'How it works',
    path: 'how-it-works',
  },
  {
    id: 2,
    label: 'How to sell',
    path: 'how_to_sell',
  },
  {
    id: 3,
    label: 'Help',
    path: 'help',
  },
];

function CategoryModal() {
  const config = useConfiguration();
  const categories = config?.categoryConfiguration?.categories ?? [];

  return (
    <div className={css.root}>
      <div className={css.content}>
        <span className={css.greeting}>Browse categories</span>

        <div className={css.accountLinksWrapper}>
          {categories.map((category, index) => {
            return (
              <div key={index}>
                <NamedLink
                  name="SearchPage"
                  className={css.inbox}
                  to={{
                    search: `?pub_categoryLevel1=${category.id}`,
                  }}
                >
                  {category.name}
                </NamedLink>
              </div>
            );
          })}
        </div>

        <div className={css.dataLinksWrapper}>
          {data.map((elm, index) => {
            return (
              <div key={index}>
                <NamedLink name="CMSPage" className={css.greeting} params={{ pageId: elm.path }}>
                  {elm.label}
                </NamedLink>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default CategoryModal;
